import React from 'react'
// import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Theme from '../components/theme'
import {
  SEO,
  Layout,
  SectionHero,
  SectionColumns,
  SectionText,
  Headline,
  SectionBanner,
} from '../components'
import page from '../data/pages/subscriptionservice.yml'
import { Section } from '../components/Section/Section'
import whiteCheck from '../images/loesungen_assetts/whitecheck.png'
import { SectionNewsletter } from '../components/Section/SectionNewsletter'

// import logoSocialCashback from '../images/logo-socialcashback.svg'

const iconStyle: React.CSSProperties = {
  position: 'relative',
  left: 'calc(50% - 46px)',
  height: '73px',
  width: '73px',
}
const checkoutBenefits = [
  {
    image: (
      <StaticImage
        src="../images/landing_aboservice/icon_impact.png"
        alt="bank-to-bank"
        className="mb-5 black m-auto"
        style={iconStyle}
      />
    ),
    title: 'Inkl. positivem Impact',
    text: 'socialcashback ist kostenlos dabei',
  },
  {
    image: (
      <StaticImage
        src="../images/landing_aboservice/icon_cost.png"
        alt="direct on account"
        className="mb-5 black m-auto"
        style={iconStyle}
      />
    ),
    title: 'Direkt aufs Konto',
    text: 'Kein Clearing-Account',
  },
  {
    image: (
      <StaticImage
        src="../images/landing_aboservice/icon_api.png"
        alt="api"
        className="mb-5 black m-auto"
        style={iconStyle}
      />
    ),
    title: 'Easy Integriert',
    text: 'Modernste Rest-API',
  },
]

const edgyBox = (row: number) => {
  return (
    <>
      <div
        style={{
          gridColumn: 'col3-start',
          gridRow: `row${row}-start`,
          backgroundImage:
            'linear-gradient(to bottom left, transparent 50%, rgb(248 249 246 / 14%) 0), linear-gradient(to bottom right, rgb(248 249 246 / 14%) 50%, transparent 0)',
          backgroundSize: '100% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top, bottom',
          width: '2rem',
        }}
      />
      <div
        style={{
          gridColumn: 'col4-start',
          gridRow: `row${row}-start`,
          backgroundImage:
            'linear-gradient(to bottom left, rgb(248 249 246 / 14%) 50%, transparent 0), linear-gradient(to top left, rgb(248 249 246 / 14%) 50%, transparent 0)',
          backgroundSize: '100% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top, bottom',
        }}
      />
    </>
  )
}

const Subscriptionservice = (): React.ReactElement => {
  return (
    <>
      <Theme>
        <SEO title={page?.title} />
        <Layout>
          <SectionHero
            textSize="medium"
            from="checkout"
            title="Mach Dein Aboangebot nachhaltig"
            titleTag="h1"
            subtitle="Unser Aboservice mit positivem Impact"
            text="Mit payactive kannst du ganz einfach wiederkehrende Zahlungen einziehen. Das ist ideal für alle Abos deiner Kund:innen. Dadurch sparst du nicht nur wertvolle Zeit, sondern steigerst zudem deine Umsätze und unterstützt zugleich mit jeder Zahlung Klimaschutz- und Bildungsprojekte. Du benötigst dafür keine Programmierkenntnisse und es fallen keine Mehrkosten für deine Kund:innen oder dein Unternehmen an!"
            image={
              <StaticImage
                src="../images/landing_aboservice/abohandy.png"
                alt="payactive App"
              />
            }
          />
          <div
            className="bg-white mt-14 md:mt-0"
            style={{
              boxShadow: '0 10px 50px rgba(0,0,0,.3)',
            }}
          >
            <SectionColumns data={checkoutBenefits} variant="white" />
          </div>
          {/* <SectionText title="Live ausprobieren:" centered />
          <div
            className="bg-white"
            style={{
              boxShadow: '0 10px 50px rgba(0,0,0,.3)',
            }}
          >
            <SectionImage
              image={
                <StaticImage
                  src="../images/jointheteam.jpg"
                  alt="Büro"
                  className="shadow-xl rounded-lg my-10"
                />
              }
            />
          </div> */}
          <SectionText title="Kosten:" headlineVariant="underline" />
          <Section containerClass="py-0">
            <div className="sm:max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl mx-auto px-3">
              <div
                className="sm:block md:grid"
                style={{
                  rowGap: '0.25rem',
                  gridTemplateColumns:
                    '[col1-start] 3rem [col2-start] auto [col3-start] 0.25rem [col4-start] 2rem [col5-start] 16rem [col5-end]',
                  gridTemplateRows:
                    '[row1-start] auto [row2-start] auto [row3-start] auto [row4-start] auto [row4-end]',
                }}
              >
                <div
                  className="py-5 px-8"
                  style={{
                    gridColumn: 'col1-start / col3-start',
                    gridRow: 'row1-start',
                    backgroundColor: 'rgb(248 249 246 / 14%)',
                  }}
                >
                  <Headline
                    fontSize="xmedium"
                    variant="underline"
                    className="pb-5"
                  >
                    subscription
                    <span className="font-thin" id="payment-service">
                      service
                    </span>
                  </Headline>
                  <h2 className="text-2xl my-2 font-serif">
                    Ideal für die Abwicklung von Abonnements und
                    Mitgliedsbeiträgen
                  </h2>
                  <ul>
                    <li
                      className="text-white font-thin"
                      style={{
                        listStyle: 'none',
                        background: `url(${whiteCheck}) no-repeat left center`,
                        backgroundSize: '30px',
                        padding: '0.5em 0 0.5rem 2.5rem',
                      }}
                    >
                      <h1 className="font-medium">
                        Inklusive Payment Services
                      </h1>

                      <h2>
                        Reibungsloser digitaler Zahlungsverkehr per
                        Sofortüberweisung oder Lastschrift.
                      </h2>
                    </li>
                    <li
                      className="text-white font-thin"
                      style={{
                        listStyle: 'none',
                        background: `url(${whiteCheck}) no-repeat left center`,
                        backgroundSize: '30px',
                        padding: '0.5em 0 0.5rem 2.5rem',
                      }}
                    >
                      <h1 className="font-medium">
                        Inklusive Billing Services
                      </h1>
                      <h2>
                        Intelligente Automatisierung wiederkehrenden Rechnungen,
                        Zahlungsmatching und Mahnwesen.
                      </h2>
                    </li>
                    <li
                      className="text-white font-thin"
                      style={{
                        listStyle: 'none',
                        background: `url(${whiteCheck}) no-repeat left center`,
                        backgroundSize: '30px',
                        padding: '0.5em 0 0.5rem 2.5rem',
                      }}
                    >
                      <h1 className="font-medium">Social Cashback</h1>
                      <h2>
                        Mit jeder Zahlung positiven, nachhaltigen Impact
                        generieren - ohne Mehrkosten.
                      </h2>
                    </li>
                  </ul>
                  <div>
                    <p />
                  </div>
                </div>
                {edgyBox(1)}
                <div
                  className="py-5 px-8 border-t border-solid md:border-none"
                  style={{
                    gridColumn: 'col5-start',
                    gridRow: 'row1-start',
                    backgroundColor: 'rgb(248 249 246 / 14%)',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <div>
                    <p>
                      pro&nbsp;<span className="font-thin">Transaktion</span>
                    </p>
                    <Headline fontSize="xmedium" variant="underline">
                      2,0 %
                    </Headline>
                    <p className="my-4 font-thin">
                      vom Zahlungsbetrag min.&nbsp;0,70 &euro; | max. 4 &euro;
                    </p>
                    <p className="text-sm font-thin">
                      F&uuml;r Hochpreistransaktionen: Bei
                      Transaktionsbetr&auml;gen ab 3.000 Euro berechnen wir
                      +&nbsp;0,05% pro Transaktion.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Section>
          <SectionBanner
            title="Early Bird werden!"
            text="Um unseren neuen Aboservice zeitnah nutzen zu können machst du am besten einen individuellen Wunschtermin mit Manuel aus. Das geht hier:"
            subtext="Manuel Born, Co-Gründer & COO von payactive"
            image={
              <StaticImage
                src="../images/manuwithstars.png"
                alt="Person mit Glitzer"
                style={{ maxWidth: 450 }}
                // className="md:w-"
              />
            }
            singlePost
            actionLinks={[
              {
                label: 'Jetzt Beratungstermin anfragen',
                url: 'termin-checkout',
              },
            ]}
          />
          <SectionNewsletter containerClass="" page="Startseite" />
        </Layout>
      </Theme>
    </>
  )
}

export default Subscriptionservice
